<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <base-material-card
      color="indigo"
      icon="mdi-account-tie"
      title="Listado de empleados"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="empleados"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-dialog
              v-model="dialog"
              scrollable
              persistent
              max-width="80%"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  fab
                  dark
                  class="mb-2"
                  v-on="on"
                >
                  <v-icon
                    dark
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                  >
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.nombre"
                            :rules="[v => !!v || 'El nombre es requerido']"
                            label="Nombres"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.apellido"
                            :rules="[v => !!v || 'El apellido es requerido']"
                            label="Apellidos"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="editedItem.email"
                            :rules="emailRules"
                            prepend-icon="mdi-email"
                            label="Correo electronico principal"
                            hint="Se usa para iniciar sesión"
                            persistent-hint
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="editedItem.role == 'ROLE_OFICIAL'">
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.emailAlt.email"
                            prepend-icon="mdi-email"
                            append-outer-icon="mdi-plus"
                            label="Correo electronico alternativo"
                            @click:append-outer="addEmail"
                          />
                          <v-card
                            class="mx-auto"
                            max-width="90%"
                            raised
                          >
                            <v-list subheader>
                              <v-subheader>Correo electronico alternativo</v-subheader>
                              <v-divider />
                              <v-list-item
                                v-for="(item, i) in editedItem.emailAlt"
                                :key="i"
                              >
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.email" />
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-btn icon>
                                    <v-icon
                                      color="error"
                                      @click="removeEmail(i, item.id)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="editedItem.telAlt.tel"
                            prepend-icon="mdi-phone"
                            label="Telefonos"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="addTel"
                          />
                          <v-card
                            class="mx-auto"
                            max-width="90%"
                            raised
                          >
                            <v-list subheader>
                              <v-subheader>Telefonos</v-subheader>
                              <v-divider />
                              <v-list-item
                                v-for="(item, i) in editedItem.telAlt"
                                :key="i"
                              >
                                <v-list-item-content>
                                  <v-list-item-title v-text="item.tel" />
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-btn icon>
                                    <v-icon
                                      color="error"
                                      @click="removeTel(i, item.id)"
                                    >
                                      mdi-delete
                                    </v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-select
                            v-model="editedItem.role"
                            :rules="[v => !!v || 'El role es requerido']"
                            :items="roles"
                            item-text="nombre"
                            item-value="id"
                            label="Role"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    {{ $t("close") }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-icon
            small
            class="mr-2"
            color="success"
            @click="editItem(item)"
          >
            mdi-pencil-outline
          </v-icon>
          <v-icon
            small
            color="error"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Recargar
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import EmpleadosApi from '@/services/api/Empleados'
  import UsuariosApi from '@/services/api/Usuarios'

  export default {
    name: 'UsuariosEmpleados',

    data: vm => {
      return {
        valid: true,
        lazy: false,
        dialog: false,
        search: '',
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        editedIndex: -1,
        empleados: [],
        roles: [
          { id: 'ROLE_USER', nombre: 'ROLE_USER' },
          { id: 'ROLE_OFICIAL', nombre: 'ROLE_OFICIAL' },
          { id: 'ROLE_NOTARIO', nombre: 'ROLE_NOTARIO' },
          { id: 'ROLE_ADMIN', nombre: 'ROLE_ADMIN' },
        ],
        headers: [
          {
            text: 'Nombre',
            align: 'left',
            sortable: true,
            value: 'nombre',
          },
          {
            text: 'Apellidos',
            align: 'left',
            sortable: true,
            value: 'apellido',
          },
          {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        editedItem: {
          id: '',
          nombre: '',
          apellido: '',
          email: '',
          emailAlt: [],
          telAlt: [],
          role: '',
        },
        defaultItem: {
          id: '',
          nombre: '',
          apellido: '',
          email: '',
          emailAlt: [],
          telAlt: [],
          role: '',
        },
        emailRules: [
          v => !!v || 'EL correo electrónico es requerido',
          v => /.+@.+\..+/.test(v) || 'Ingresa un correo valido',
        ],
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo empleado' : 'Modificar empleado'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      fetchEmpleados () {
        UsuariosApi.getAllUsers()
          .then(empleados => {
            this.empleados = empleados
            this.empleados.map(function (x) {
              x.role = x.role[0]
              if (x.role === 'ROLE_OFICIAL') {
                EmpleadosApi.getEmpleadoByUser(x.id)
                  .then(empleado => {
                    x.telAlt = empleado.telAlt
                    x.emailAlt = empleado.emailAlt
                  })
                  .catch(error => console.log(error))
              }
            })
            console.log(this.empleados)
          })
          .catch(error => console.log(error))
      },

      initialize () {
        this.fetchEmpleados()
      },

      editItem (item) {
        this.editedIndex = this.empleados.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        const index = this.empleados.indexOf(item)
        confirm('Are you sure you want to delete this item?') &&
          this.empleados.splice(index, 1)
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            const updEmpleados = this.editedItem
            const indexItem = this.editedIndex
            EmpleadosApi.updateEmpleados(this.editedItem.id, this.editedItem)
              .then(response => {
                Object.assign(this.empleados[indexItem], updEmpleados)
                this.text = 'Registro modificado correctamente'
                this.snackbar = true
                this.colorSnak = 'success'
                this.editedIndex = -1
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser modificado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
                this.editedIndex = -1
              })
          } else {
            const addEmpleados = this.editedItem
            EmpleadosApi.addEmpleados(this.editedItem)
              .then(response => {
                addEmpleados.id = response.id
                this.empleados.push(addEmpleados)
                this.text = 'Registro creado correctamente'
                this.colorSnak = 'success'
                this.snackbar = true
                this.editedIndex = -1
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser creado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          }
          this.close()
        }
      },

      addEmail () {
        var value = this.editedItem.emailAlt.email && this.editedItem.emailAlt.email.trim()
        // eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!value || !re.test(value)) {
          this.text = 'Debes ingresar un correo electrónico valido'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        if (!this.editedItem.id) {
          this.text = 'Antes debes crear el empleado.'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        const emails = {
          empleado: this.editedItem.id,
          email: this.editedItem.emailAlt.email,
        }

        EmpleadosApi.addEmailEmpleados(emails)
          .then(response => {
            this.editedItem.emailAlt.push({
              id: response.id,
              email: this.editedItem.emailAlt.email,
            })
            this.editedItem.emailAlt.email = ''
            this.text = 'Registro creado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser creado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      removeEmail (i, item) {
        const index = i
        if (confirm('Realmente desea eliminar este registro?')) {
          EmpleadosApi.deleteEmail(item)
            .then(response => {
              this.editedItem.emailAlt.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      addTel () {
        var value = this.editedItem.telAlt.tel && this.editedItem.telAlt.tel.trim()
        if (!value) {
          this.text = 'Debes ingresar un teléfono valido'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        if (!this.editedItem.id) {
          this.text = 'Antes debes crear el empleado.'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        const tlfns = {
          empleado: this.editedItem.id,
          telefono: this.editedItem.telAlt.tel,
        }

        EmpleadosApi.addTelEmpleados(tlfns)
          .then(response => {
            // tlfns.id = response.id
            this.editedItem.telAlt.push({
              id: response.id,
              tel: this.editedItem.telAlt.tel,
            })
            this.editedItem.telAlt.tel = ''
            this.text = 'Registro creado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser creado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      removeTel (i, item) {
        const index = i
        console.log(index)
        if (confirm('Realmente desea eliminar este registro?')) {
          EmpleadosApi.deleteTel(item)
            .then(response => {
              this.editedItem.telAlt.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },
    },

  }
</script>

<style scoped>
.v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
